<template>
  <div class="logo"></div>
  <LandingPage class="content fill" msg="Willkommen auf fschaupp.me" />
  <MenuArea class="menu" />
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import MenuArea from "./components/MenuArea.vue";

export default {
  name: "fschaupp.me",
  components: {
    LandingPage,
    MenuArea,
  },
};
</script>

<style lang="scss">
@import "styles/main";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background-color: $background;
}

.logo {
  grid-area: logo;
  width: 100%;

  background-image: url("/images/logo.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.content {
  grid-area: content-area;
  color: $font-color;
}

.menu {
  grid-area: menu-area;
}

.fill {
  width: 100%;
  height: 100%;
}
</style>
