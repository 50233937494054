<template>
  <a :href="href" :target="target">
    <div class="btn">{{ name }}</div>
  </a>
</template>

<script>
export default {
  props: {
    name: String,
    href: String,
    target: {
      type: String,
      required: false,
    },
  },
};
</script>
