<template>
  <div id="menu">
    <MenuButton name="HOME" href="/"></MenuButton>
    <MenuButton name="P5 Playground" href="/p5"></MenuButton>
    <MenuButton
      name="Cloud"
      href="https://cloud.fschaupp.me"
      target="_blank"
    ></MenuButton>
  </div>
</template>

<script>
import MenuButton from "./MenuButton.vue";

export default {
  components: {
    MenuButton,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints.scss";

#menu {
  width: 100%;

  display: flex;

  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  align-content: center;

  @media (min-width: $desktop) {
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
  }
}
</style>
